/* You can add global styles to this file, and also import other style files */
@import './assets/scss/variables.scss';

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import "@ng-select/ng-select/themes/default.theme.css";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/scss/angular-calendar.scss";


[data-bs-theme=light] {
  --body-bg: #f4f5f6;
  --bs-card-color: #fff;
  --navbar-bg: #fff;
  --bs-body-bg: #fff;

  --cal-selected-bg: #047857;
  --cal-hover-bg: #ededed;
}
[data-bs-theme=dark] {
  --body-bg: #121212;
  --bs-card-color: #1f1f1f;
  --navbar-bg: #1f1f1f;
  --bs-body-bg: #1f1f1f;

  --cal-selected-bg: #047857;
  --cal-hover-bg: #2c343a;
}


html, body { height: 100%; }
body {
  background-color: var(--body-bg);
}

.btn-primary {
  --bs-btn-disabled-bg: #3B3F4B;
  --bs-btn-disabled-border-color: #3B3F4B;
}


.bg-img-login {
  background-image: url("./assets/images/login-blur.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  position: absolute;
  height: 100vh;
  width: 100%;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);

  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.container-login {
  height: 100%;
  min-height: 100svh;
  width: 100%;
  max-width: 432px;
}


.cursor-pointer {
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
}



[class*=warning]:not([class*=outline]), [class*=warning][class*=active] {
  color: white;
}

.mat-mdc-table {
  background-color: var(--bs-table-bg) !important;
}
.mat-mdc-header-cell, .mat-mdc-cell {
  border-bottom-color: var(--bs-light-border-subtle) !important;
}



// Custom badge styling
.badge-status {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.bg-completed {
  background: var(--bs-success-bg-subtle);
  color: var(--bs-success-text-emphasis);
}

.bg-cancelled {
  background: var(--bs-danger-bg-subtle);
  color: var(--bs-danger-text-emphasis);
}

.bg-validated {
  background: var(--bs-info-bg-subtle);
  color: var(--bs-info-text-emphasis);
}

.bg-pending {
  background: var(--bs-warning-bg-subtle);
  color: var(--bs-warning-text-emphasis);
}


// Custom card styling
.card-order {
  display: flex;
  width: 200px;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  border-radius: 12px;
  background: rgba(5, 150, 105, 0.50);
  /* Shadow (MD) */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10);

  text-decoration: none;
  color: var(--bs-body-color);

  &:hover {
    cursor: pointer;
    background: rgba(5, 150, 105, 0.70);
  }
}


// Custom ng-select styling
.ng-select .ng-select-container {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border: 1px solid var(--bs-secondary-border-subtle);
}

// Increase z-index for google places autocomplete dropdown in modals
.pac-container {
  z-index: 10000 !important;
}




// Material table row styling
.disabled-row td {
  background-color: #f2f2f2 !important;
}

.selected-row td {
  background-color: #e5f1ff !important;
}


.popover-calendar {
  max-width: 385px;
  width: 385px;
}

/* angular-calendar calendar styles */
.container-calender {

  .cal-month-view {

    .cal-header .cal-cell {
      color: $secondary
    }

    .cal-days {
      border: none;
    }

    .cal-days .cal-cell-row {
      border-bottom: none;
    }

    .cal-day-cell:not(:last-child) {
      border-right: none;
    }

    .cal-day-cell {
      min-height: 0;
      text-align: center;
      //padding: 2px 5px;
    }

    .cal-day-number {
      font-size: 13px;
      line-height: 30px;
      font-weight: 600;
      opacity: 1;
      margin: 0 auto;
      float: none;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 1px solid transparent;
      display: block;
      cursor: pointer;

      &.selected {
        background-color: var(--cal-selected-bg);
        border-color: var(--cal-selected-bg);
        color: #fff;
      }

      &.today {
        border-color: var(--cal-selected-bg);
      }

      &.future-assigned-orders {
        border-color: #808080FF;
      }
      
      &.future-unassigned-orders {
        border-color: red;
      }
    }

    .cal-day-number.cal-in-week-day {
      opacity: 1;
      cursor: pointer;
    }

    .cal-disabled {
      pointer-events: none;
    }

    .cal-disabled .cal-day-number {
      opacity: 0.3;
    }

    .cal-day-cell.cal-today {
      background-color: transparent;

      .cal-day-number {
        font-size: 13px;
      }
    }

    .cal-cell-row .cal-cell:hover {
      background-color: transparent;

      .cal-day-number {
        background-color: var(--cal-hover-bg);

        &.selected {
          background-color: var(--cal-selected-bg);
        }
      }
    }
  }

}


@media (max-width: 400px) {

  .popover-calendar {
    max-width: 300px;
    width: 300px;
  }

  .container-calender {
    .cal-month-view {
      .cal-day-cell {
        padding-left: 0;
        padding-right: 0;
      }

      .cal-day-number {
        font-size: 12px;
        width: 30px;
        height: 30px;
        line-height: 24px;
      }
    }
  }

}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

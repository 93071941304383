
$primary: #047857;
$warning: #FF8328;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

$sidebar-width: 300px;